const state = {
    isclose: false
}
const mutations = {
  setIsclose: (state ,isclose) => {
      state.isclose = isclose;
  }
}
export default {
  state,
  mutations
}