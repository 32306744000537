import axios from 'axios'
import bshop_global from  "./global"
const network = axios.create({
    baseURL:bshop_global.url + bshop_global.version,
    // baseURL:"api", //配置跨域
    timeout: 50000,
    headers: {'Content-Type': 'application/json;charset=utf-8'},
    withCredentials: false,
    transformRequest: [function (data) {
      // 对 data 进行任意转换处理
      let request = {
        req:data.req,
        req_meta:data.req_meta
      }
      return JSON.stringify(request);
    }],
})

//添加请求拦截器
network.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});
// 发送请求成功后的返回拦截器
network.interceptors.response.use(
    response => {
      return response.data
    },
    error => {
      console.log(error)
      return false
    }
)

export default network