import network from "@/libs/network"
import bshop_global from  "@/libs/global"

/***********************************登录*********************************** */
//获取经销商信息
export const getSysOfficeInfo = (req) => {
    let data = {
        req:req,
        req_meta:{
            openId:localStorage.getItem('openId')
        }
    }
    return network({
        data,
        url: 'user/officeinfo',
        method: 'POST'
    })
}
//通过wxCode和customerId登录
export const baselogin = (req) => {
    let data = {
        req:req,
        req_meta:{
            openId:localStorage.getItem('openId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'user/baseLogin',
        method: 'POST'
    })
}
//获取验证码
export const getCode = (req) => {
    let data = {
        req:req,
        req_meta:{
            openId:localStorage.getItem('openId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'user/getCode',
        method: 'POST'
    })
}
//登陆
export const login = (req) => {
    let data = {
        req:req,
        req_meta:{
            openId:localStorage.getItem('openId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'user/login',
        method: 'POST'
    })
}
//获取我的地址
export const deliveryAddress = (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'order/user/deliveryAddress',
        method: 'POST'
    })
}
/***********************************订货*********************************** */
//商品类别
export const category = (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'goods/category',
        method: 'POST'
    })
}
//根据类别获取商品列表
export const goodsList = (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'goods/list',
        method: 'POST'
    })
}
//获取商品详情
export const goodsDetail = (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'goods/skudetail',
        method: 'POST'
    })
}
// export const goodsDetail = (req) => {
//     let data = {
//         req:req,
//         req_meta:{
//             customerId:localStorage.getItem('customerId'),
//             sysOfficeId:localStorage.getItem('sysOfficeId')
//         }
//     }
//     return network({
//         data,
//         url: 'goods/detail',
//         method: 'POST'
//     })
// }
//加入购物车
export const cartAdd = (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'shoppingcart/add',
        method: 'POST'
    })
}
//加入购物车
export const cartAdd2 = (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'shoppingcart/addGlass',
        method: 'POST'
    })
}
//编辑购物车
export const cartEdit = (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'shoppingcart/editQuantity',
        method: 'POST'
    })
}
//编辑购物车
export const cartEdit2 = (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'shoppingcart/editQuantityGlass',
        method: 'POST'
    })
}
/***********************************购物车*********************************** */
//购物车列表
export const cartList = () => {
    let data = {
        req:{},
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'shoppingcart/list',
        method: 'POST'
    })
}
//购物车删除
export const cartdele = (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'shoppingcart/dele',
        method: 'POST'
    })
}
//购物车计算
export const computePromotion = (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'shoppingcart/computePromotion',
        method: 'POST'
    })
}
//购物车编辑
export const editQuantity = (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'shoppingcart/editQuantity',
        method: 'POST'
    })
}
/***********************************我的*********************************** */
//个人信息
export const userInfo = () => {
    let data = {
        req:{},
        req_meta:{
            openId:localStorage.getItem('openId'),
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'user/mycenter',
        method: 'POST'
    })
}
//添加地址
export const addAddress = (req) => {
    let data = {
        req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'order/user/adddeliveryAddress',
        method: 'POST'
    })
}
//删除地址
export const delAddress = (req) => {
    let data = {
        req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'order/user/deledeliveryAddress',
        method: 'POST'
    })
}
/***********************************订单*********************************** */
//获取支付方式
export const paytype = () => {
    let data = {
        req:{},
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'order/paytype',
        method: 'POST'
    })
}
//订单计算价格
export const orderComputePromotion = (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'order/computePromotion',
        method: 'POST'
    })
}
//订单支付 生成未支付订单
export const orderCreate = (req) => {
    let data = {
        req:req,
        req_meta:{
            openId:localStorage.getItem('openId'),
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'order/create',
        method: 'POST'
    })
}
//订单列表
export const getOrderList = (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            // customerId:'df782431d00846d29b4f85678a54cad4', //闫老师的customerId
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'order/list',
        method: 'POST'
    })
}
//订单详情
export const orderDetailInfo = (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'order/detail',
        method: 'POST'
    })
}
//支付宝支付界面获取订单信息
export const payOrderInfo = (data) => {
    return network({
        data,
        url: 'order/alipay/orderInfo',
        method: 'POST'
    })
}
//支付宝支付form提交地址
export const payOrderformAddress = () => {
    return bshop_global.payUrl + "/alipay/payment"
}

/***********************************留资页面*********************************** */
export const applyinfo = (req) => {
    let data = {
        req:req,
        req_meta:{
        }
    }
    return network({
        data,
        url: 'sys/applyuse/applyinfo',
        method: 'POST'
    })
}

export const record = (req) => {
    let data = {
        req:req,
        req_meta:{
        }
    }
    return network({
        data,
        url: 'sys/access/record',
        method: 'POST'
    })
}
/***********************************拍照单*********************************** */
//记录单图片上传
export const recordUploadfile = (fromData) => {
    return network({
        data:fromData,
        headers: {'Content-Type': 'mutilpart/form-data'},
        url: 'recordorder/uploadfile',
        method: 'POST',
        transformRequest: [function (data) {
            return data
        }],
    })
}
//记录单创建
export const recordCreate = (req) => {
    let data = {
        req:req,
        req_meta:{
            customer_id:localStorage.getItem('customerId'),
            sys_office_id:localStorage.getItem('sysOfficeId'),
            customer_master_id:localStorage.getItem('customermasterId'),
        }
    }
    return network({
        data,
        url: 'recordorder/add',
        method: 'POST'
    })
}
//拍照记录单列表
export const recordOrderList = (req) => {
    let data = {
        req:req,
        req_meta:{
            customer_id:localStorage.getItem('customerId'),
            sys_office_id:localStorage.getItem('sysOfficeId'),
            customer_master_id:localStorage.getItem('customermasterId'),
        }
    }
    return network({
        data,
        url: 'recordorder/list',
        method: 'POST'
    })
} 
//拍照记录单各状态数量
export const recordOrderTabs = () => {
    let data = {
        req:{},
        req_meta:{
            customer_id:localStorage.getItem('customerId'),
            sys_office_id:localStorage.getItem('sysOfficeId'),
            customer_master_id:localStorage.getItem('customermasterId'),
        }
    }
    return network({
        data,
        url: 'recordorder/tabs',
        method: 'POST'
    })
} 
//拍照记录单详情基本信息
export const recordOrderBasic = (req) => {
    let data = {
        req:req,
        req_meta:{
            customer_id:localStorage.getItem('customerId'),
            sys_office_id:localStorage.getItem('sysOfficeId'),
            customer_master_id:localStorage.getItem('customermasterId'),
        }
    }
    return network({
        data,
        url: 'recordorder/basicInfo',
        method: 'POST'
    })
}
//拍照记录单图片列表
export const recordOrderImg = (req) => {
    let data = {
        req:req,
        req_meta:{
            customer_id:localStorage.getItem('customerId'),
            sys_office_id:localStorage.getItem('sysOfficeId'),
            customer_master_id:localStorage.getItem('customermasterId'),
        }
    }
    return network({
        data,
        url: 'recordorder/oderimgs',
        method: 'POST'
    })
}
//拍照记录单处理进度
export const recordOrderProgress = (req) => {
    let data = {
        req:req,
        req_meta:{
            customer_id:localStorage.getItem('customerId'),
            sys_office_id:localStorage.getItem('sysOfficeId'),
            customer_master_id:localStorage.getItem('customermasterId'),
        }
    }
    return network({
        data,
        url: 'recordorder/progress',
        method: 'POST'
    })
}
//拍照记录单录入的单子信息
export const recordOrderDetail = (req) => {
    let data = {
        req:req,
        req_meta:{
            customer_id:localStorage.getItem('customerId'),
            sys_office_id:localStorage.getItem('sysOfficeId'),
            customer_master_id:localStorage.getItem('customermasterId'),
        }
    }
    return network({
        data,
        url: 'recordorder/detail',
        method: 'POST'
    })
}
//拍照记录单取消
export const recordOrderCancel = (req) => {
    let data = {
        req:req,
        req_meta:{
            customer_id:localStorage.getItem('customerId'),
            sys_office_id:localStorage.getItem('sysOfficeId'),
            customer_master_id:localStorage.getItem('customermasterId'),
        }
    }
    return network({
        data,
        url: 'recordorder/cancel',
        method: 'POST'
    })
}
//拍照记录单计算价格
export const recordOrderBalance = (req) => {
    let data = {
        req:req,
        req_meta:{
            customer_id:localStorage.getItem('customerId'),
            sys_office_id:localStorage.getItem('sysOfficeId'),
            customer_master_id:localStorage.getItem('customermasterId'),
        }
    }
    return network({
        data,
        url: 'recordorder/balance',
        method: 'POST'
    })
}
//拍照记录单计算
export const recordComputepromotion = (req) => {
    let data = {
        req:req,
        req_meta:{
            customer_id:localStorage.getItem('customerId'),
            sys_office_id:localStorage.getItem('sysOfficeId'),
            customer_master_id:localStorage.getItem('customermasterId'),
        }
    }
    return network({
        data,
        url: 'recordorder/computepromotion',
        method: 'POST'
    })
}
//订单取消
export const orderCancel = (req) => {
    let data = {
        req:req,
        req_meta:{
            customer_id:localStorage.getItem('customerId'),
            sys_office_id:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'order/cancel',
        method: 'POST'
    })
}

//订单去支付 再次支付
export const orderPayAgain= (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId'),
            openId:localStorage.getItem('openId')
        }
    }
    return network({
        data,
        url: 'order/pay/again',
        method: 'POST'
    })
}
//订单去支付 更改支付方式
export const OrderUpdatePayType= (req) => {
    let data = {
        req:req,
        req_meta:{
            customerId:localStorage.getItem('customerId'),
            sysOfficeId:localStorage.getItem('sysOfficeId'),
            openId:localStorage.getItem('openId')
        }
    }
    return network({
        data,
        url: 'order/update/paytype',
        method: 'POST'
    })
}

//获取用户余额
export const GetUserBalance= (req) => {
    let data = {
        req:req,
        req_meta:{
            sys_office_id:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'order/select/balance',
        method: 'POST'
    })
}
// 获取余额明细
export const integralRecord = (req) =>{
    let data = {
        req:req,
        req_meta:{
            customer_id:localStorage.getItem('customerId'),
            sys_office_id:localStorage.getItem('sysOfficeId')
        }
    }
    return network({
        data,
        url: 'integral/record',
        method: 'POST'
    })
}

// 小程序绑定公众号openid
export const bindWxInfo = (req) =>{
    let data = {
        req:req,
        req_meta:{}
    }
    return network({
        // baseURL: 'https://miwx.cs-hebg3.group/',
        baseURL: 'https://miwx.miyunplus.com/',
        data,
        url: 'dev/applet/v1/bind_wx_info',
        method: 'POST'
    })
}