import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'
import cartcount from "./module/cartcount"
import temporaryaddress from "./module/temporaryaddress"
import searchclass from "./module/searchclass"
import keepalive from "./module/keepalive"
import nopaycount from "./module/nopaycount"
import pricetipclosestatus from "./module/pricetipclosestatus"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  getters,
  modules: {
    cartcount,
    keepalive,
    searchclass,
    temporaryaddress,
    nopaycount,
    pricetipclosestatus
  }
})