import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import importDirective from '@/directives' 
import './libs/rem.js';  //px转化为rem
import $ from "jquery"
import { getSysOfficeInfo , baselogin } from "@/apis/api"
import { getUrlKey } from '@/libs/utils'
import bshop_global from  "@/libs/global"
import 'swiper/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
//vant组件使用-start
import { Button} from 'vant';
import { List} from 'vant';
import { PullRefresh } from 'vant';
import { Skeleton } from 'vant';
import { Icon } from 'vant';
import { Search} from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { Image } from 'vant';
import { Loading } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Tab, Tabs } from 'vant';
import { Sku } from 'vant';
import { Field } from 'vant';
import { Form } from 'vant';
import { RadioGroup, Radio } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { Toast } from 'vant';
import { Stepper } from 'vant';
import { Picker } from 'vant';
import { Overlay } from 'vant';
import { AddressList , AddressEdit } from 'vant';
import { Dialog } from 'vant';
import { TreeSelect } from 'vant';
import { Popup } from 'vant';
import { ImagePreview } from 'vant';
import { Uploader } from 'vant';
import { ActionSheet } from 'vant';
import { Notify } from 'vant';
import { swipe, SwipeItem  } from 'vant';
import { Step, Steps  } from 'vant';
import { Lazyload } from 'vant';
Vue.use(Button)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Skeleton)
Vue.use(Icon)
Vue.use(Search)
Vue.use(DropdownMenu).use(DropdownItem)
Vue.use(Image)
Vue.use(Loading)
Vue.use(Tabbar).use(TabbarItem)
Vue.use(Tab).use(Tabs)
Vue.use(Sku);
Vue.use(Field);
Vue.use(Form);
Vue.use(Toast);
Vue.use(Stepper);
Vue.use(Picker);
Vue.use(Overlay);
Vue.use(AddressEdit).use(AddressList);
Vue.use(Dialog);
Vue.use(RadioGroup).use(Radio)
Vue.use(Checkbox).use(CheckboxGroup)
Vue.use(TreeSelect);
Vue.use(Popup);
Vue.use(ImagePreview);
Vue.use(Uploader);
Vue.use(ActionSheet);
Vue.use(Notify)
Vue.use(swipe).use(SwipeItem)
Vue.use(Step).use(Steps)
Vue.use(Lazyload, {
  lazyComponent: true,
});
//vant组件使用-end

Vue.prototype.getImgSizeJpg = function(src,width, height) {
  var font = window.getComputedStyle(document.documentElement)["fontSize"];
  var scale = (font.replace("px", "")) * 2;
  var widthStr = Math.floor(width*scale)
  var heightStr = Math.floor(height*scale)
  // var imgUrl = src + "_" + widthStr + "x" + heightStr + ".jpg";
  var imgUrl = src + "?x-oss-process=image/resize,limit_0,m_lfit,h_"+heightStr+",w_" + widthStr;
  return imgUrl;
}

/**
 * 注册指令
 */
importDirective(Vue)
Vue.config.productionTip = false
/**
 * 获取路径中的wxcode
 */
if(getUrlKey("code")) {
  localStorage.setItem("wxcode", getUrlKey("code"));
}
/**
 * 获取路径中的appid
 */
if(getUrlKey("appid")) {
	localStorage.setItem("appId", getUrlKey("appid"));
}
/**
 * 认证身份
 */
var urlparam = window.location.hash;
if(urlparam.indexOf('payinfo') >= 0 || urlparam.indexOf('payment-success') >= 0|| urlparam.indexOf('payment-error') >= 0||urlparam.indexOf('error') >= 0 || urlparam.indexOf('alipay-error') >= 0|| urlparam.indexOf('alipay-success') >= 0 ||urlparam.indexOf('leaving-capital') >= 0 ||urlparam.indexOf('get_wx_code') >= 0) {
  initVue()
}else{
  let data = {
    appId:localStorage.getItem("appId")
  }
  getSysOfficeInfo(data).then(res=>{
    if(res.code == 0){
      localStorage.setItem('sysOfficeId',res.res.id);
      localStorage.setItem('sysOfficeName',res.res.name);
      localStorage.setItem('sysOfficeImg',res.res.img);
      document.title = res.res.name
      login()
    }else{
      bshop_global.goErr.isGo = true //通过router.beforeEach验证  如果goErr是true 就要去跳转error页面
      bshop_global.goErr.params = {
        info:"登录",
        position:"微信公众号"
      }
      initVue()
    }
  })
}
/**
 * 基本登录
 */
function login(){
  let data = {
    wxcode:localStorage.getItem("wxcode")
  }
  baselogin(data).then(res=>{
    if(res.code == 0){
      localStorage.setItem('openId',res.res.opendId);
      localStorage.setItem('customerId',res.res.customerId);
      localStorage.setItem('customermasterId',res.res.customermasterId);
      localStorage.setItem('customerName',res.res.customerName);
      initVue()
    }else if(res.code == 405){//没有customerId
      localStorage.setItem('openId',res.res.opendId);
      localStorage.removeItem('customerId');
      localStorage.removeItem('customerName');
      localStorage.removeItem('customermasterId');
      initVue()
    }else{
      bshop_global.goErr.isGo = true  //通过router.beforeEach验证  如果goErr是true 就要去跳转error页面
      initVue()
    }
  })
}
/**
 * 初始化vue
 */
function initVue(){
  new Vue({
      el: '#app',
      router,
      store,
      render: h => h(App),
  }).$mount('#app')
}