let bshop_global = {
    // DEV 开发  PRO 生产
	// url:'http://192.168.2.86:9090/dev/border/', //占强的本地地址 
	// url:'http://192.168.2.52:9090/dev/border/', //明举的本地地址 
	// url:'http://192.168.2.63:9090/dev/border/', //丽萍的本地地址 
	url:'https://miwx.miyunplus.com/dev/border/', //正式地址
	// url:'https://miwx.cs-hebg3.group/dev/border/', //测试地址
    version:"v1/", //接口的版本
    // payUrl:'http://192.168.2.52:9090', //明举的本地地址
    // payUrl:'http://192.168.2.63:9090', //丽萍的本地地址
    payUrl:'https://miwx.miyunplus.com', //正式地址
    // payUrl:'https://miwx.cs-hebg3.group', //测试地址
    goErr:{
        isGo:false,
        params:{}
    },
    bShopUrl:"https://bshop.miyunplus.com", //正式地址
    // bShopUrl:"https://bshop.cs-hebg3.group", //测试地址
    // 农科尚品 经销商id  现在默认 1、写死100运费 2、不满足1500不可以支付 3、不显示拍单 农科尚品正式id b5f22f6fd59a4a949a5a7c026f7d91b6
    // code =-1 appid=-1 经销商id  6bcd32b8065e4987ba5429c0b8b028d9  测试趣瞅啥经销商id
    sysOfficeId:'b5f22f6fd59a4a949a5a7c026f7d91b6' 
};
export default bshop_global