import { getOrderList } from "@/apis/api"
const state = {
    nopaycount: sessionStorage.getItem('nopaycount') || 0
}
const mutations = {
  setNoPayCount: (state ,count) => {
      state.nopaycount = count;
      sessionStorage.setItem('nopaycount', count);
  }
}
const actions = {
  getNoPayCount: (context) => {
    let data = {
        orderStatus:1,
        page:1,
        pageSize:10
    }
    return new Promise((resolve, reject) => {
      getOrderList(data).then(res=>{
        if(res.code == 0){
          context.commit('setNoPayCount',res.res.count)
        }
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}
export default {
  state,
  mutations,
  actions
}