import { cartList } from "@/apis/api"
const state = {
    cartcount: sessionStorage.getItem('cartcount') || "",
}
const mutations = {
  setCount: (state ,count) => {
      state.cartcount = count;
      sessionStorage.setItem('cartcount', count);
  }
}
const actions = {
  setCartCount: (context) => {
      cartList().then(res=>{
          if(res.code == 0){
            context.commit('setCount',res.res.skulist.length)
          }
      })
  }
}
export default {
  state,
  mutations,
  actions
}