import Vue from 'vue'
import Router from 'vue-router'
import routes from './router'
import bshop_global from  "@/libs/global"

Vue.use(Router)
const VueRouterPush = Router.prototype.push 
Router.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
const router  = new Router({
  routes,
  mode:"hash"
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  Vue.prototype.beforeRouter = from;
  // 判断该路由是否需要登录权限
  if (to.matched.some(record => record.meta.requireLogin)){
    if(bshop_global.goErr.isGo){
      next({
        name: 'error',
        replace:"true",
        params:bshop_global.goErr.params
      })
    }
    // 判断当前用户的登录信息customerId是否存在
    else if (!localStorage.getItem('customerId')) {
      next({
        path: '/login',
        replace:"true"
      })
    }
  }
  // to.query.pathurl 跳转到相应的页面
  if(to.query.pathurl){
    next({
      path: '/' + to.query.pathurl,
      replace:"true"
    })
  }
  next();
})

export default router
