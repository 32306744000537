const state = {
    tempaddress: sessionStorage.getItem('tempaddress') || "",
}
const mutations = {
  setTempAddress: (state ,address) => {
      state.tempaddress = address;
      sessionStorage.setItem('tempaddress', address);
  },
  delTempAddress: (state) => {
    state.tempaddress = "";
    sessionStorage.setItem('tempaddress', "");
}
}
export default {
  state,
  mutations,
}