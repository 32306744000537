const state = {
    searchclassone: sessionStorage.getItem('searchclassone') || "",
    searchclasstwo: sessionStorage.getItem('searchclasstwo') || "",
    searchclassthree: sessionStorage.getItem('searchclassthree') || "",
}
const mutations = {
    setSearchClassOne :(state ,searchclassone) => {
        if(searchclassone){
            state.searchclassone = searchclassone;
            sessionStorage.setItem('searchclassone', JSON.stringify(searchclassone)); 
        }
    },
    setSearchClassTwo :(state ,searchclasstwo) => {
        if(searchclasstwo){
            state.searchclasstwo = searchclasstwo;
            sessionStorage.setItem('searchclasstwo', JSON.stringify(searchclasstwo));
        }
    },
    setSearchClassThree :(state ,searchclassthree) => {
        if(searchclassthree){
            state.searchclassthree = searchclassthree;
            sessionStorage.setItem('searchclassthree', JSON.stringify(searchclassthree));
        }
    },
    clearSearchclassone :(state) =>{
        state.searchclassone = ''
        sessionStorage.removeItem('searchclassone'); 
    },
    clearSearchClassTwo :(state) =>{
        state.searchclasstwo = ''
        sessionStorage.removeItem('searchclasstwo');
    },
    clearSearchClassThree :(state) =>{
        state.searchclassthree = ''
        sessionStorage.removeItem('searchclassthree');
    }
}
export default {
  state,
  mutations,
}