const directives = {
  htmltit: {
    inserted: function (el) {
      document.title = el.dataset.title
    }
  },
  drag:{
    inserted:function(el,binding){
      let odiv = el;//el 当前元素
      odiv.onmousedown= (e)=>{
        e.preventDefault();
          let disX = e.clientX - odiv.offsetLeft;
          let disY = e.clientY - odiv.offsetTop;
          let left = null;
          let top = null;
          //将此时的位置传出去
          binding.value({x: odiv.offsetLeft, y: odiv.offsetTop})
          document.onmousemove = (e) => {
            e.stopPropagation()
            //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            left = e.clientX - disX;
            top = e.clientY - disY;
            //移动当前元素
            odiv.style.left = left + 'px';
            odiv.style.top = top + 'px';
            //将此时的位置传出去
            // binding.value({x:left,y:top})
          }
          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
          };
      }
    }
  }
}
export default directives
