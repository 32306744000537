const routes = [
    {
      path: '/',
      name: 'index',
      meta:{
        title:localStorage.getItem('sysOfficeName'),
        keepAlive: true,
        requireLogin:true // 当前路由需要校验，不需要就不用写
      },
      component: () => import('@/view/Home')  //新版的是home 旧版的是index文件
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/view/Login')
    },
    {
      path: '/good-lists',
      name: 'good-lists',
      meta:{
        requireLogin:true // 当前路由需要校验，不需要就不用写
      },
      component: () => import('@/view/GoodList')
    },
    {
      path: '/order',
      name: 'order',
      meta: {
          title:'订单列表',
          keepAlive: true,
          requireLogin:true
      },
      component: () => import('@/view/Order')
    },
    {
      path: '/order-detail',
      name: 'order-detail',
      meta: {
        requireLogin:true
      },
      component: () => import('@/view/OrderDetail')
    },
    {
      path: '/own',
      name: 'own',
      meta: {
        requireLogin:true
      },
      component: () => import('@/view/Own')
    },
    {
      path: '/address-list',
      name: 'address-list',
      meta: {
        requireLogin:true
      },
      component: () => import('@/view/Own/component/addressList')
    },
    {
      path: '/my-address',
      name: 'my-address',
      meta: {
        requireLogin:true
      },
      component: () => import('@/view/Own/component/address')
    },
    // 余额明细
    {
      path: '/price-detail',
      name: 'price-detail',
      meta: {
        requireLogin:true
      },
      component: () => import('@/view/Own/component/priceDetail')
    },
    {
      path: '/search',
      name: 'search',
      meta: {
        requireLogin:true
      },
      component: () => import('@/view/Search')
    },
    {
      path: '/search-list',
      name: 'search-list',
      meta: {
        requireLogin:true
      },
      component: () => import('@/view/SearchList')
    },
    {
      path: '/cart',
      name: 'cart',
      meta: {
        requireLogin:true
      },
      component: () => import('@/view/Cart')
    },
    {
      path: '/order-confirm',
      name: 'order-confirm',
      meta: {
        requireLogin:true
      },
      component: () => import('@/view/OrderConfirm')
    },
    {
      path: '/payment-success',
      name: 'payment-success',
      component: () => import('@/view/PaymentSuccess')
    },
    {
      path: '/payment-error',
      name: 'payment-error',
      component: () => import('@/view/PaymentError')
    },
    {
      path: '/alipay-error',
      name: 'alipay-error',
      component: () => import('@/view/AlipayError')
    },
    {
      path: '/alipay-success',
      name: 'alipay-success',
      component: () => import('@/view/AlipaySuccess')
    },
    {
      path: '/payinfo',
      name: 'payinfo',
      component: () => import('@/view/PayInfo')
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('@/view/Error/error')
    },
    {
      path: '/leaving-capital',
      name: 'leaving-capital',
      component: () => import('@/view/leavingCapital')
    },
    //记录单列表
    {
      path: '/photo-order',
      name: 'photo-order',
      meta: {
          title:'拍单',
          keepAlive: true,
          requireLogin:true
      },
      component: () => import('@/view/PhotoOrder')
    },
    //新增记录单
    {
      path: '/add-photo-order',
      name: 'add-photo-order',
      meta: {
        requireLogin:true
      },
      component: () => import('@/view/PhotoOrder/component/ceshiUploader')
    },
    //记录单详情
    {
      path: '/photo-order-detail',
      name: 'photo-order-detail',
      meta: {
        requireLogin:true
      },
      component: () => import('@/view/PhotoOrder/component/photoOrderDetail')
    },
    //记录单确认订单页面
    {
      path: '/photo-order-confirm',
      name: 'photo-order-confirm',
      meta: {
        requireLogin:true
      },
      component: () => import('@/view/PhotoOrder/component/orderConfirm')
    },
    //获取code和openid带到小程序中
    {
      path: '/get_wx_code.html',
      name: 'get_wx_code',
      component: () => import('@/view/GetWxCode')
    }
  ]
export default routes
