const state = {
    keepAliveList: [],
}
const mutations = {
    keepAlive(state, keepAlives) {
        !state.keepAliveList.includes(keepAlives) &&
        state.keepAliveList.push(keepAlives)
    },
    delKeepAlive(state){
        state.keepAliveList = []
    }
}
const getters = {
    keepAliveList : state => state.keepAliveList
}
export default {
  state,
  mutations,
  getters
}