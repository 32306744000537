// 引入微信sdk
import wx from 'weixin-js-sdk';
import router from '@/router/index'
import { Toast } from 'vant';
export const regPhone=(phone)=>{
    if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone))){ 
        return false; 
    } else{
        return true; 
    }
}

export const isEmojiCharacter=(substring)=>{
    for ( var i = 0; i < substring.length; i++) {
        var hs = substring.charCodeAt(i);
        if (0xd800 <= hs && hs <= 0xdbff) {
            if (substring.length > 1) {
                var ls = substring.charCodeAt(i + 1);
                var uc = ((hs - 0xd800) * 0x400) + (ls - 0xdc00) + 0x10000;
                if (0x1d000 <= uc && uc <= 0x1f77f) {
                    return true;
                }
            }
        } else if (substring.length > 1) {
            const ls = substring.charCodeAt(i + 1);
            if (ls == 0x20e3) {
                return true;
            }
        } else {
            if (0x2100 <= hs && hs <= 0x27ff) {
                return true;
            } else if (0x2B05 <= hs && hs <= 0x2b07) {
                return true;
            } else if (0x2934 <= hs && hs <= 0x2935) {
                return true;
            } else if (0x3297 <= hs && hs <= 0x3299) {
                return true;
            } else if (hs == 0xa9 || hs == 0xae || hs == 0x303d || hs == 0x3030
                || hs == 0x2b55 || hs == 0x2b1c || hs == 0x2b1b
                || hs == 0x2b50) {
                return true;
            }
        }
    }
}
//获取路径上的参数
export const getUrlKey = (name)=>{
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
	var data = window.location.href.split("?");
	var param ="";
	for(var d in data){
		if(d!=="0"){
			var str1 =  data[d].split("#/")[0]
			param = param+"&"+str1
		}
	}
	param = param.substring(1,param.length);

	var data = param.match(reg);
	if(data !== null) {
		return unescape(data[2]);
	}
	return null;
}
// 将数组按照特定属性排序
export function compare (key) {
    return function (object1,object2) {
        var value1 = object1[key];
        var value2 = object2[key];

        if (value1 < value2) {
            return -1;
        } else if (value1 > value2) {
            return 1;
        } else {
            return 0;
        }
    };
}
//时间戳转化为时间
export function formatTime (number, format) {
    let time = new Date(number)
    let newArr = []
    let formatArr = ['Y', 'M', 'D', 'h', 'm', 's']
    newArr.push(time.getFullYear())
    newArr.push(formatNumber(time.getMonth() + 1))
    newArr.push(formatNumber(time.getDate()))

    newArr.push(formatNumber(time.getHours()))
    newArr.push(formatNumber(time.getMinutes()))
    newArr.push(formatNumber(time.getSeconds()))
    for (let i in newArr) {
        format = format.replace(formatArr[i], newArr[i])
    }
    return format;
}

// 格式化日期，如月、日、时、分、秒保证为2位数
export function formatNumber (n) {
    n = n.toString()
    return n[1] ? n : '0' + n;
}
//获取对象的第一个key
export function get_object_first_attribute(data){
    for (var key in data){
        return key;
    }
}
//配置微信jdk
export function wxConfig(data){
　　 wx.config({
　　　　　　debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
　　　　　　appId: data.appid, // 必填，公众号的唯一标识
　　　　　　timestamp: data.timestamp, // 必填，生成签名的时间戳
　　　　　　nonceStr: data.noncestr, // 必填，生成签名的随机串
　　　　　　signature: data.signature,// 必填，签名，见附录1
　　　　　　jsApiList: ['checkJsApi', 'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareWeibo', 'hideMenuItems', 'showMenuItems', 'hideAllNonBaseMenuItem',
        'showAllNonBaseMenuItem', 'translateVoice', 'startRecord', 'stopRecord', 'onRecordEnd', 'playVoice', 'pauseVoice', 'stopVoice', 'uploadVoice', 'downloadVoice', 'chooseImage',
        'previewImage', 'uploadImage', 'downloadImage', 'getNetworkType', 'openLocation', 'getLocation', 'hideOptionMenu', 'showOptionMenu', 'closeWindow', 'scanQRCode',
        'chooseWXPay', 'openProductSpecificView', 'addCard', 'chooseCard', 'openCard'
        ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
　　　});
    wx.ready(() => {
        callPay(data)
    });
    wx.error(function(res){
        console.log('err', res)
    });
}

export function callPay(wxJsApiParam) { /** 调用支付 * */
    if(typeof WeixinJSBridge == "undefined") {
        if(document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', this.jsApiCall, false);
        } else if(document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', this.jsApiCall);
            document.attachEvent('onWeixinJSBridgeReady', this.jsApiCall);
        }
    } else {
        jsApiCall(wxJsApiParam);
    }
}
/** 呼叫微信JS支付密码框 * */
export function jsApiCall(wxJsApiParam) {
    WeixinJSBridge.invoke('getBrandWCPayRequest', {
        'appId': wxJsApiParam.appId, // 公众号标识
        'timeStamp': wxJsApiParam.timeStamp, // 时间戳
        'nonceStr': wxJsApiParam.nonceStr, // 随机数
        'package': wxJsApiParam.packageStr, // 预处理id
        'signType': wxJsApiParam.signType, // 微信签名方式
        'paySign': wxJsApiParam.paySign
        // 微信签名
    }, function(res) {
        // WeixinJSBridge.log(res.err_msg);
        if(res.err_msg == 'get_brand_wcpay_request:ok') {
            router.replace({ name:"payment-success" })
            Toast("支付成功")
        }else if(res.err_msg == 'get_brand_wcpay_request:cancel'){
            router.replace({ name:"order" })
            Toast("取消支付")
        }else{
            router.replace({ name:"payment-error"})
            Toast("支付失败")
        }
    });
}